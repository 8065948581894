import axios from "axios";
import { MarketContext } from "../Context";
import React, { useContext, useState, useEffect } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import WorkInProgress from "../component/WorkInProgress.jsx";
import { Navigate, useNavigate } from "react-router";
// import "../css/initialCSS.css";

const InitialSetupcomponent = () => {
  const { token, loca, getVerify, setReloadLeft, user,appname } =
    useContext(MarketContext);
  const [initButton1, setInitButton1] = useState(false);
  const [initButton2, setInitButton2] = useState(false);
  const [initButton3, setInitButton3] = useState(false);
  const [initButton4, setInitButton4] = useState(false);
  const [initButton5, setInitButton5] = useState(false);
  const [initButton6, setInitButton6] = useState(false);
  const [initButton7, setInitButton7] = useState(false);
  const [initButton8, setInitButton8] = useState(false);
  const [initButton9, setInitButton9] = useState(false);
  const [initButton10, setInitButton10] = useState(false);
  const [isProcessing, setProcessing] = useState([
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
  ]);
  const [disableArr, setDisableArr] = useState([
    { state: "true" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
  ]);
  const [now, setNow] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [text, setText] = useState("");


  const initialsetuprecordcallapi = () => {
    let proArr = isProcessing;
    proArr[0].state = "true";
    setProcessing([...proArr]);
    setNow(10);
    axios
      .get(loca + "/marketplace/initial/setuprecord", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[0].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[0].state = "success";
          arr[1].state = "true";
          setDisableArr(arr);
          setNow(100);
          initialsetupcallapi1();
        }
      });
  };

  const initialdisplaynull = () => {
    axios
      .get(loca + "/marketplace/initialsetup/delete", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          const modul = res.data;
          if ("result" in modul) {
            setReloadLeft(true);
            setCurrentApplication();
          }
        }
      });
  };
  const initialsetupcallapi1 = () => {
    let proArr = isProcessing;
    proArr[1].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup1", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[1].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[1].state = "success";
          arr[2].state = "true";
          setDisableArr(arr);
          setInitButton1(true);
          setNow(100);
          initialsetupcallapi2();
        }
      });
  };

  const initialsetupcallapi2 = () => {
    let proArr = isProcessing;
    proArr[2].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup2", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[2].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[2].state = "success";
          arr[3].state = "true";
          setDisableArr(arr);
          setInitButton2(true);
          setNow(100);
          initialsetupcallapi3();
        }
      });
  };

  const initialsetupcallapi3 = () => {
    let proArr = isProcessing;
    proArr[3].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup3", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[3].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[3].state = "success";
          arr[4].state = "true";
          setDisableArr(arr);
          setInitButton3(true);
          setNow(100);
          initialsetupcallapi4();
        }
      });
  };

  const initialsetupcallapi4 = () => {
    let proArr = isProcessing;
    proArr[4].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup4", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[4].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[4].state = "success";
          arr[5].state = "true";
          setDisableArr(arr);
          setInitButton4(true);
          setNow(100);
          initialsetupcallapi5();
        }
      });
  };

  const initialsetupcallapi5 = () => {
    let proArr = isProcessing;
    proArr[5].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup5", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[5].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[5].state = "success";
          arr[6].state = "true";
          setDisableArr(arr);
          setInitButton5(true);
          setNow(100);
          initialsetupcallapi6();
        }
      });
  };

  const initialsetupcallapi6 = () => {
    let proArr = isProcessing;
    proArr[6].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup6", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[6].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[6].state = "success";
          arr[7].state = "true";
          setDisableArr(arr);
          setInitButton6(true);
          setNow(100);
          initialsetupcallapi7();
        }
      });
  };

  const initialsetupcallapi7 = () => {
    let proArr = isProcessing;
    proArr[7].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup7", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[7].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[7].state = "success";
          arr[8].state = "true";
          setDisableArr(arr);
          setInitButton7(true);
          setNow(100);
          initialsetupcallapi8();
        }
      });
  };

  const initialsetupcallapi8 = () => {
    let proArr = isProcessing;
    proArr[8].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup8", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[8].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[8].state = "success";
          arr[9].state = "true";
          setDisableArr(arr);
          setInitButton8(true);
          setNow(100);
          initialsetupcallapi9();
        }
      });
  };

  const initialsetupcallapi9 = () => {
    let proArr = isProcessing;
    proArr[9].state = "true";
    setProcessing([...proArr]);
    setNow(0);
    axios
      .get(loca + "/marketplace/initial/setup9", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[9].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[9].state = "success";
          arr[10].state = "true";
          setDisableArr(arr);
          setInitButton9(true);
          setNow(100);
          initialsetupcallapi10();
        }
      });
    };
    const initialsetupcallapi10 = () => {
      console.log("initial10");
      
      let proArr = isProcessing;
      proArr[10].state = "true";
      setProcessing([...proArr]);
      setNow(0);
      axios
      .get(loca + "/marketplace/initial/setup10", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        console.log(res);
        
        if (res.status === 200) {
          proArr[10].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[10].state = "success";
          arr[11].state = "true";
          setDisableArr(arr);
          setInitButton10(true);
          setNow(100);
          initialdisplaynull();
        }
      });
  };

  const setCurrentApplication = () => {
    let proArr = isProcessing;
    proArr[10].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/marketplace/set/userapplication/marketplace", {
        headers: {
          authorization: "Bearer " + token,
          "market-application": appname,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[10].state = "false";
          setProcessing([...proArr]);
          let arr = disableArr;
          arr[10].state = "success";
          setDisableArr(arr);
          getVerify();
          navigate(user + "/dashboard");
          setText("your current application setup is done!");
        }
      });
  };
  
  return (
    <div className="container text-center">
      <div className="alert alert-warning mt-2">Do not refresh this page!</div>
      {loading === true ? <WorkInProgress /> : ""}
      <div className="row row-cols-4 justify-content-center  mt-2">
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetuprecordcallapi()}
            disabled={
              disableArr[0].state === "false" ||
              disableArr[0].state === "success" ||
              isProcessing[0].state === "true"
            }
            className={
              disableArr[0].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[0].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup Records"
            )}
          </button>
        </div>
      </div>
      <div className="row row-cols-4 justify-content-center  mt-5">
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi1()}
            disabled={
              disableArr[1].state === "false" ||
              disableArr[1].state === "success" ||
              isProcessing[1].state === "true"
            }
            className={
              disableArr[1].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[1].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 1"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi2()}
            disabled={
              disableArr[2].state === "false" ||
              disableArr[2].state === "success" ||
              isProcessing[2].state === "true"
            }
            className={
              disableArr[2].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[2].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 2"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi3()}
            disabled={
              disableArr[3].state === "false" ||
              disableArr[3].state === "success" ||
              isProcessing[3].state === "true"
            }
            className={
              disableArr[3].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[3].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 3"
            )}
          </button>
        </div>
      </div>
      <div className="row row-cols-4 justify-content-center  mt-5">
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi4()}
            disabled={
              disableArr[4].state === "false" ||
              disableArr[4].state === "success" ||
              isProcessing[4].state === "true"
            }
            className={
              disableArr[4].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[4].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 4"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi5()}
            disabled={
              disableArr[5].state === "false" ||
              disableArr[5].state === "success" ||
              isProcessing[5].state === "true"
            }
            className={
              disableArr[5].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[5].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 5"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi6()}
            disabled={
              disableArr[6].state === "false" ||
              disableArr[6].state === "success" ||
              isProcessing[6].state === "true"
            }
            className={
              disableArr[6].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[6].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 6"
            )}
          </button>
        </div>
       
      </div>
      <div className="row row-cols-4 justify-content-center  mt-5">
      <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi7()}
            disabled={
              disableArr[7].state === "false" ||
              disableArr[7].state === "success" ||
              isProcessing[7].state === "true"
            }
            className={
              disableArr[7].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[7].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 7"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            value={text}
            onClick={() => initialsetupcallapi8()}
            disabled={
              disableArr[8].state === "false" ||
              disableArr[8].state === "success" ||
              isProcessing[8].state === "true"
            }
            className={
              disableArr[8].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[8].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 8"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            value={text}
            onClick={() => initialsetupcallapi9()}
            disabled={
              disableArr[9].state === "false" ||
              disableArr[9].state === "success" ||
              isProcessing[9].state === "true"
            }
            className={
              disableArr[9].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[9].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 9"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            value={text}
            onClick={() => initialsetupcallapi10()}
            disabled={
              disableArr[10].state === "false" ||
              disableArr[10].state === "success" ||
              isProcessing[10].state === "true"
            }
            className={
              disableArr[10].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[10].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 10"
            )}
          </button>
        </div>
      </div>
      <div className="row row-cols-4 justify-content-center  mt-5">
        <div className="col-sm-2">
          <button
            type="button"
            value={text}
            onClick={() => setCurrentApplication()}
            disabled={
              disableArr[11].state === "false" ||
              disableArr[11].state === "success" ||
              isProcessing[11].state === "true"
            }
            className={
              disableArr[11].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[11].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Set Current Application"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialSetupcomponent;
